import { ApolloClient } from '@apollo/client'
import { takeLatest, call, put, delay, spawn } from 'redux-saga/effects'
import { actionTypes } from './index'
import {
  Product,
  ProductDetailItem,
  ProductApiRead,
  ChildrenProduct,
  ProductApiImage,
  ProductImage,
} from '@typings/entities/Product'
import createFetchSaga from '@utils/store/createFetchSaga'
import productsGetQuery from '@queries/productsGetQuery'
import productCreateQuery from '@queries/productCreateQuery'
import productUpdateQuery from '@queries/productUpdateQuery'
import productBatchDeleteQuery from '@queries/productBatchDeleteQuery'
import productActivateQuery from '@queries/productActivateQuery'
import productDeactivateQuery from '@queries/productDeactivateQuery'
import productImageCreateQuery from '@queries/productImageCreateQuery'
import productImageDeleteQuery from '@queries/productImageDeleteQuery'
import productBatchActivateQuery from '@queries/productBatchActivateQuery'
import productBatchDeactivateQuery from '@queries/productBatchDeactivateQuery'
import productGetQuery from '@queries/productGetQuery'
import getRuntimeConfig from '@utils/getRuntimeConfig'
import { createSuccessAction } from '@utils/store/createFetchSaga'
import createAxiosRequest from '@utils/createAxiosRequest'
import { FileUploadWithProgress } from '@typings/file'
import { ApiInitialError } from '@typings/entities/Error'
import * as Sentry from '@sentry/browser'
import fetchAsyncExport from '@utils/fetchAsyncExport'
import fetchFileAuthorized from '@utils/fetchFileAuthorized'
import toBase64 from '@utils/toBase64'
import { getDirectFetchSaga } from '@utils/store/fetchDirectModule'
import { sagaDebounce } from '@store/helpers'
import { displayToast } from '@utils/toast'
import { t } from '@lingui/macro'
import isArray from 'lodash/isArray'
import sumBy from 'lodash/sumBy'
import isObject from 'lodash/isObject'
import { actions as uiActions } from '@store/ui'

const importFinishedStatus = 'finished'
const importCheckDelay = 5000
const importPullingTimeInMinutes = 30

type ImportGetResult = {
  errors: Record<string, Array<{ error: string; row: number }>> | []
  status: string
  imported: unknown[]
}

const convertImage = (image: ProductApiImage): ProductImage => ({
  id: image.id,
  createdAt: image.createdAt,
  originalName: image.originalName,
  urlSmall: image.urlSmall || image.url,
  urlLarge: image.urlLarge || image.url,
})

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({
      query: productsGetQuery,
      variables: action.payload,
    })
    .then(({ data }) => ({ data: data?.productsGet?.results }))
}

const fetchDetail = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const result = await client
    .query({ query: productGetQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.productGet }))

  result.data.images = (result.data.images || []).map(convertImage)
  result.data.intImages = (result.data.intImages || []).map(convertImage)

  const productIds = (result.data.childrenProducts || []).map((item: ChildrenProduct) => item.productId)
  const productsPromise = productIds.length
    ? client
        .query({
          query: productsGetQuery,
          variables: {
            select: ['id', 'productSku', 'internalSku', 'name', 'referenceNumbers', 'workAroundLot', 'eshops', 'active'],
            criteria: {
              id: { in: productIds },
            },
            limit: 1000,
          },
        })
        .then(({ data }) => data?.productsGet?.results)
    : Promise.resolve([])

  const [products] = await Promise.all([productsPromise])

  if (productIds && result.data.type == 'bundle') {
    result.data.childrenProducts = result.data.childrenProducts.map(
      ({ quantity, productId }: ChildrenProduct): ProductDetailItem => ({
        id: productId,
        quantity,
        product: products.find(({ id }: ProductApiRead) => id == productId),
      }),
    )
  }

  return result
}

const fetchCreateProduct = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const result = await client
    .query({ query: productCreateQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.productCreate }))
  const product: Product = result?.data
  // upload new images
  const images: Product['images'] = action.payload?.images
  for (const image of images || []) {
    if (!image.image) continue
    const transformed = await toBase64(image.image)
    await client.query({ query: productImageCreateQuery, variables: { productId: product.id, image: transformed } })
  }
}

const fetchUpdateProduct = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const result = await client
    .query({ query: productUpdateQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.productUpdate }))
  const product: Product = result?.data
  // upload new images
  const images: Product['images'] = action.payload?.images
  const imagePromises = []
  for (const image of images || []) {
    if (image.id) continue // is not new - has been already uploaded
    console.log(image)
    if (!image.image) continue
    const transformed = await toBase64(image.image)
    imagePromises.push(
      client.query({
        query: productImageCreateQuery,
        variables: { productId: product.id, image: transformed, originalName: image?.image?.name },
      }),
    )
  }
  // delete removed images
  for (const productImage of product.images || []) {
    const found = images?.find((image) => image.id === productImage.id)
    if (found) continue
    imagePromises.push(
      client.query({ query: productImageDeleteQuery, variables: { productId: product.id, imageId: productImage.id } }),
    )
  }
  await Promise.all(imagePromises)
}

const fetchActivateProduct = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: productActivateQuery, variables: action.payload })
}

const fetchDeactivateProduct = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({
    query: productDeactivateQuery,
    variables: action.payload,
    context: {
      suppressError: (err: ApiInitialError) => {
        return err?.messageTemplate?.includes('Not possible to deactivate')
      },
    },
  })
}

const fetchBatchDeleteProduct = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({
    query: productBatchDeleteQuery,
    variables: action.payload,
    context: {
      suppressError: (err: ApiInitialError) => {
        return err?.messageTemplate?.includes('Not possible to delete/remove')
      },
    },
  })
}

const fetchBatchActivateProduct = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: productBatchActivateQuery, variables: action.payload })
}

const fetchBatchDeactivateProduct = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client.query({ query: productBatchDeactivateQuery, variables: action.payload })
}

function* downloadImportTemplate(action: Action): Generator {
  const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
  const url = `${apiBaseUrl}product/template.${action?.payload?.fileFormat}`
  yield put(createSuccessAction(actionTypes.downloadImportTemplate.run, action, null))
  yield call(() => window.location.replace(url))
}

function* downloadItemsTemplate(action: Action): Generator {
  const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
  const url = `${apiBaseUrl}import/template-item.${action?.payload?.fileFormat}`
  yield put(createSuccessAction(actionTypes.downloadImportTemplate.run, action, null))
  return fetchFileAuthorized(url, `item_template.${action?.payload?.fileFormat}`)
}

export type ImportResultMerged = Record<string, { ean: string; occurrences: number; sku: string }>

export type ImportResult = {
  products: { product: ProductApiRead; quantity: number }[]
  merged: ImportResultMerged
}

const importItems = async (client: ApolloClient<any>, action: Action): Promise<FileUploadWithProgress<ImportResult>> => {
  let uploadProgress = 0
  const uploadPromise = new Promise<ImportResult>((resolve, reject) => {
    const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
    const formData = new FormData()
    formData.append('organisation', action.payload.organisation)
    formData.append('file', action.payload.file)
    createAxiosRequest({
      method: 'POST',
      url: `${apiBaseUrl}import/import-item`,
      data: formData,
      onUploadProgress: (progress) => {
        if (!progress.total) return
        uploadProgress = progress.loaded / progress.total
      },
    })
      .then(async (response) => {
        const productQuantityMap: { [product: string]: number } = {}
        const productIds = (response.data.result || []).map(({ id, quantity }: any) => {
          productQuantityMap[id] = quantity
          return id
        })
        const products = await (productIds.length
          ? client
              .query({
                query: productsGetQuery,
                variables: {
                  select: [
                    'id',
                    'productSku',
                    'internalSku',
                    'name',
                    'type',
                    'referenceNumbers',
                    'workAroundLot',
                    'eshops',
                    'active',
                  ],
                  criteria: {
                    id: { in: productIds },
                  },
                  nested: true,
                  limit: 1000,
                },
              })
              .then(({ data }) => data?.productsGet?.results)
          : Promise.resolve([]))

        const results = products.map((product: ProductApiRead) => ({
          product,
          quantity: productQuantityMap[product.id],
        }))
        resolve({ products: results, merged: response.data.merged })
      })
      .catch(({ response }) => {
        // TODO remake this
        let message: string | string[]
        if (response.data?.violations && response.data?.violations.length) {
          message = response.data?.violations.map((v: ApiInitialError) => `${v.propertyPath}: ${v.message}`) as string[]
          if (response.data?.message) {
            message.unshift(`${response.data?.message}:`)
          }
        } else {
          message = response.data?.message || response.statusText
        }
        Sentry.captureMessage(String(message))
        reject(message)
      })
  })
  return Promise.resolve({
    uploadPromise,
    getProgress: (): number => uploadProgress,
  })
}

const checkImport = async (id: string) => {
  const getImportData = new Promise((resolve, reject) => {
    const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
    createAxiosRequest({
      method: 'GET',
      url: `${apiBaseUrl}product/import/${id}`,
    })
      .then((res) => resolve(res?.data))
      .catch((e) => {
        const message = e?.response?.data?.message || e?.message
        if (message) {
          displayToast({
            type: 'error',
            title: t({ id: 'cornerDialog.failed.title', message: 'Failed' }),
            text: `${t({ id: 'notification.import.failed', message: 'Import failed!' })} ${message}`,
          })
          Sentry.captureMessage(message)
          reject(message)
        }
      })
  })
  const res = await Promise.resolve(getImportData)
  return res && (JSON.parse(res as string) as ImportGetResult)
}

function* checkImportPeriodically(id?: string) {
  if (!id) return
  let isPending = true
  const endTime = Date.now() + importPullingTimeInMinutes * 60 * 1000

  while (isPending) {
    const now = Date.now()
    const data = (yield call(checkImport, id)) as ImportGetResult
    const errorCount = isArray(data?.errors) ? data?.errors?.length : sumBy(Object.values(data?.errors), 'length')
    const successCount = data?.imported ? Object.values(data?.imported).length : 0
    const isFinished = data?.status === importFinishedStatus
    const isSuccess = errorCount === 0 && isFinished
    const isFailed = errorCount > 0 && isFinished
    const intent = isSuccess ? 'success' : isFailed ? 'error' : 'info'

    let result: string[] = []
    if (isObject(data?.errors)) {
      for (const value of Object.values(data?.errors)) {
        for (const item of value) {
          result = [...result, `Row ${item?.row}: ${item?.error}`]
        }
      }
    }

    yield put(
      uiActions.pushProcessDialog({
        isLoading: !isFinished,
        intent,
        successCount,
        errorCount,
        result,
      }),
    )

    if (now > endTime || isSuccess || isFailed) {
      isPending = false
    }

    yield delay(importCheckDelay)
  }
}

const getImportResultId = async (file: File) => {
  const uploadPromise = new Promise((resolve, reject) => {
    const apiBaseUrl = getRuntimeConfig('FRONTEND__GRAPHQL_ENDPOINT_URL')
    const formData = new FormData()
    formData.append('file', file)
    createAxiosRequest({
      method: 'POST',
      url: `${apiBaseUrl}product/import`,
      data: formData,
    })
      .then((res) => {
        resolve(res?.data?.resultId)
      })
      .catch((e) => {
        const message = e?.response?.data?.message || e?.message
        if (message) {
          displayToast({
            type: 'error',
            title: t({ id: 'cornerDialog.failed.title', message: 'Failed' }),
            text: `${t({ id: 'notification.import.failed', message: 'Import failed!' })} ${message}`,
          })
          Sentry.captureMessage(message)
          reject(message)
        }
      })
  })

  return Promise.resolve(uploadPromise)
}
function* importProducts(action: Action) {
  const id = (yield call(getImportResultId, action.payload.file)) as string
  yield spawn(checkImportPeriodically, id)
  yield put(
    createSuccessAction(actionTypes.importProducts.run, action, {
      uploadPromise: new Promise((resolve) => resolve(true)),
      getProgress: () => 100,
    }),
  )
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return
  yield sagaDebounce(200, actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
  yield takeLatest(actionTypes.loadDetail.run, createFetchSaga(actionTypes.loadDetail.run, fetchDetail))
  yield sagaDebounce(200, actionTypes.loadListDirect, getDirectFetchSaga(fetchList))
  yield takeLatest(actionTypes.create.run, createFetchSaga(actionTypes.create.run, fetchCreateProduct))
  yield takeLatest(actionTypes.edit.run, createFetchSaga(actionTypes.edit.run, fetchUpdateProduct))
  yield takeLatest(actionTypes.deleteProducts.run, createFetchSaga(actionTypes.deleteProducts.run, fetchBatchDeleteProduct))
  yield takeLatest(actionTypes.activateProduct.run, createFetchSaga(actionTypes.activateProduct.run, fetchActivateProduct))
  yield takeLatest(actionTypes.deactivateProduct.run, createFetchSaga(actionTypes.deactivateProduct.run, fetchDeactivateProduct))
  yield takeLatest(actionTypes.activateProducts.run, createFetchSaga(actionTypes.activateProducts.run, fetchBatchActivateProduct))
  yield takeLatest(
    actionTypes.deactivateProducts.run,
    createFetchSaga(actionTypes.deactivateProducts.run, fetchBatchDeactivateProduct),
  )
  yield takeLatest(actionTypes.downloadImportTemplate.run, downloadImportTemplate)
  yield takeLatest(actionTypes.importProducts.run, importProducts)
  yield takeLatest(actionTypes.exportProducts.run, createFetchSaga(actionTypes.exportProducts.run, fetchAsyncExport('product')))
  yield takeLatest(actionTypes.downloadItemsTemplate.run, downloadItemsTemplate)
  yield takeLatest(actionTypes.importItems.run, createFetchSaga(actionTypes.importItems.run, importItems))
}
